function initializeDatepicker() {
    let selected = retrieve('selected');

    let available = selected.timeslots.filter(function (timeslot) {
        return timeslot.available;
    });

    if (available.length === 0) {
        $('.date-picker-day').html('No timeslots available');
        return;
    }

    let selectableDates = available.map(function (timeslot) {
        return moment(timeslot.start).startOf('day').unix();
    });

	let date = new Date();
	let endDate = new Date(date.getTime() + 28 * 24 * 60 * 60 * 1000);

	var date_input = $('.datepicker-wrapper .datepicker');

    date_input
        .datepicker('remove');

    date_input
		.datepicker({
            format: 'mm/dd/yyyy',
            startDate: date,
            endDate: endDate,
            enabledHours : false,
            container: '.datepicker-wrapper',
            toggleActive: true,
            beforeShowDay: function (date) {
                var unix_timestamp = moment(date).startOf('day').unix();
                return selectableDates.indexOf(unix_timestamp) !== -1;
            }
        })
        .on('changeDate', function(e) {
            var momentDate = moment(e.date);
            $('.datepicker-input').val(momentDate.format('YYYY-MM-DD'));

            document.querySelector('.checkbox-timeslot-all').checked = false;
            renderTimeSlotsPerDate(momentDate.format('YYYY-MM-DD'));

            clickEventInput();
            $('.date-picker-month').html(momentDate.format('MMMM'));
            $('.date-picker-day').html(momentDate.format('D'));
            $('.date-picker-year').html(momentDate.format('YYYY'));
        });
}

function renderTimeSlotsPerDate(selectedDate) {
    HandlebarsIntl.registerWith(Handlebars);
    var source   = Handlebars.compile(document.getElementById("timeslot-template").innerHTML);
    var wrapper = document.querySelector('.timeslot-wrapper-js');
    while(wrapper.firstChild) {
        wrapper.removeChild(wrapper.firstChild)
    }

    var allSelectable = true;
    retrieve('selected').timeslots
        .filter(function (timeslot) {
            return moment(timeslot.start).format('YYYY-MM-DD') === selectedDate;
        })
        .forEach(function (timeslot) {
            allSelectable &= timeslot.available;
            timeslot.startTime = moment(timeslot.start).format('HH:mm');
            timeslot.endTime = moment(timeslot.end).format('HH:mm');
            wrapper.innerHTML += source(timeslot);
            console.log(timeslot);
        });

    let selectAllWrapper = document.querySelector('.select-all-wrapper-js');
    if(allSelectable)
        selectAllWrapper.classList.remove('--not-active');
    else
        selectAllWrapper.classList.add('--not-active');
}

function openDatepicker()Â {
	document.querySelector('.backdrop').classList.add('active-backdrop');
	clickEventInput();
}

function closeDatepicker(e) {
	if(e.target.classList.contains('backdrop') ||Â e.target.classList.contains('timerpicker-btn')) {
		document.querySelector('.backdrop').classList.remove('active-backdrop');
	}
}

function getCheckedInputs() {
	let inputs = document.querySelectorAll('.checkbox-timeslot');
    var selectedTimeSlots = Array
        .from(inputs)
        .filter(function (input) {
            return input.checked;
        }).map(function (input) {
            return {
                startDate: input.parentNode.querySelector('.date-start-js').innerHTML,
                endDate: input.parentNode.querySelector('.date-end-js').innerHTML
            }
        });

    let selected = retrieve('selected');
    selected.selectedTimeslots = selectedTimeSlots;
    store('selected', selected);

    var startDate = selectedTimeSlots[0].startDate;
    var endDate = selectedTimeSlots[selectedTimeSlots.length - 1].endDate;

    $('.timeslot-range-start-js').html(startDate);
    $('.timeslot-range-end-js').html(endDate);

    return [startDate, endDate];
}

function clickEventInput(){
	let inputs = document.querySelectorAll('.checkbox-timeslot');
	inputs.forEach((input) => {
		input.addEventListener('click', (event) => {
			inputClicked(event, inputs);
		}, false)
	})
}

function inputClicked(event, inputs) {
	var checkedInputs = [];
	inputs.forEach((input) => {
		if(input.checked){
			checkedInputs.push(input);
		}
	});
	if(event.target.checked) {
		timeSlotChecked(inputs, checkedInputs, event.target);
	} else {
		timeSlotUnchecked(inputs, checkedInputs, event.target);
	}
}

function timeSlotChecked(inputs, checkedInputs, clickedInput) {
	if(checkedInputs.length === inputs.length) {
		document.querySelector('.checkbox-timeslot-all').checked = true;
	}

	if(checkedInputs.length > 1) {
		let startIndex = getTimeSlotIndex(inputs, checkedInputs[0]);
		let endIndex = getTimeSlotIndex(inputs, checkedInputs[checkedInputs.length-1]);
		for(let i = startIndex; i < endIndex; i++) {
			if(!(inputs[i].parentNode.classList.contains('--not-available'))) {
				inputs[i].checked = true;
			} else {
				alert('You can only select timeslots which are together')
				uncheckAllInputs(inputs);
			}
		}
	}
}

function timeSlotUnchecked(inputs,checkedInputs, clickedInput) {
	document.querySelector('.checkbox-timeslot-all').checked = false;
	if(checkedInputs.length > 1) {
		const startIndex = getTimeSlotIndex(inputs, clickedInput);
		for(let i = startIndex; i < inputs.length; i++) {
			inputs[i].checked = false;
		}
	}
}

function getTimeSlotIndex(inputs, checkedInput) {
	return Array.from(inputs).findIndex(item => item === checkedInput);
}

function uncheckAllInputs(inputs) {
	for(let i = 0; i < inputs.length; i++) {
		inputs[i].checked = false;
	}
}

function checkAllInputs(event){
    document
        .querySelectorAll('.checkbox-timeslot')
        .forEach((input) => {
            input.checked = event.target.checked;
        })
}
